<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import UserService from '@/services/user'
// import defaultStoreImage from '../../../assets/images/default-store.png'

export default {
  page: {
    title: "Detalhes da Oficina",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detalhes da Oficina",
      user: {
        address: {},
        bankData: {},
        services: [],
      },
      userId: '',
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.load()
  },
  methods: {
    load(hideLoading = false) {
      let loader;
      
      if (!hideLoading) {
        loader = this.$loading.show();
      }

      UserService.getUserById(this.userId).then(res => {
        this.user = res.data;

        if (this.user.bankSlipTaxes) {
          this.user.bankSlipTaxes = (this.user.bankSlipTaxes || 0).toFixed(2).replace('.', ',');
        }

        if (loader) {
          loader.hide();
        }
      }).catch(() => {
        if (loader) {
          loader.hide();
        }
      })
    },
    saveConfig() {
      let loader = this.$loading.show();

      const data = {
        minimumDaysBankSlip: this.user.minimumDaysBankSlip,
        daysToBankSlipDueDate: this.user.daysToBankSlipDueDate,
        bankSlipTaxes: this.user.bankSlipTaxes,
        addTaxesToBankSlip: this.user.addTaxesToBankSlip,
      }

      UserService.updateHirer(this.userId, data).then(res => {
        this.user = res.data;

        this.$toast.open('Configurações da oficina atualizadas');

        this.load(true);

        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },

    saveMain() {
      let loader = this.$loading.show();

      const data = {
        name: this.user.name,
        cnpj: this.user.cnpj,
        email: this.user.email,
        phoneNumber: this.user.phoneNumber,
        additionalEmail: this.user.additionalEmail,
      }

      UserService.updateHirer(this.userId, data).then(() => {
        this.$toast.open('Dados atualizados');
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },

    saveAddress() {
      let loader = this.$loading.show();

      const data = {
        address: this.user.address,
      }

      UserService.updateHirer(this.userId, data).then(() => {
        this.$toast.open('Dados atualizados');
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dados Principais</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <div class="row mt-4">
              <div class="col-sm-2 text-center">
                <img
                  class="rounded-circle avatar-xl user-avatar"
                  :src="user.picture"
                  data-holder-rendered="true"
                  v-if="user.picture"
                />

                <div class="rounded-circle avatar-xs m-1 avatar-letter-big" v-if="!user.picture && user.name">{{user.name.toUpperCase().substring(0,1)}}</div>
              </div>

              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input id="name" name="name" type="text" class="form-control" v-model="user.name" />
                    </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="cnpj">CNPJ</label>
                      <input id="cnpj" name="cnpj" type="tel" class="form-control" v-mask="'##.###.###/####-##'" v-model="user.cnpj" />
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="manufacturerbrand">E-mail</label>
                      <input id="email" name="email" type="email" class="form-control" v-model="user.email" />
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="phoneNumber">Telefone</label>
                      <input id="phoneNumber" name="phoneNumber" type="tel" class="form-control" v-mask="'(##) #####-####'" v-model="user.phoneNumber" />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="additionalEmail">E-mail Adicional</label>
                      <input id="additionalEmail" name="additionalEmail" type="email" class="form-control" v-model="user.additionalEmail" />
                    </div>
                  </div>
                </div>

                <button class="btn btn-primary mr-1 mt-3" @click="saveMain">Salvar Dados Principais</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Endereço</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <div class="row mt-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="name">Endereço (logradouro)</label>
                      <input id="name" name="name" type="text" class="form-control" v-model="user.address.street" />
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="manufacturername">Número</label>
                      <input
                        id="cnpj"
                        name="cnpj"
                        type="text"
                        class="form-control"
                        v-model="user.address.number"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="manufacturerbrand">Complemento</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        class="form-control"
                        v-model="user.address.complement"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="district">Bairro</label>
                      <input id="district" name="district" type="text" class="form-control" v-model="user.address.district" />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="city">Cidade</label>
                      <input
                        id="city"
                        name="city"
                        type="text"
                        class="form-control"
                        v-model="user.address.city"
                      />
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label for="state">UF</label>
                      <input
                        id="state"
                        name="state"
                        type="state"
                        class="form-control"
                        v-model="user.address.state"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="manufacturerbrand">CEP</label>
                      <input
                        id="zipcode"
                        name="zipcode"
                        type="zipcode"
                        class="form-control"
                        v-model="user.address.zipcode"
                        v-mask="'#####-###'"
                      />
                    </div>
                  </div>
                </div>

                <button class="btn btn-primary mr-1 mt-3" @click="saveAddress">Salvar Endereço</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Configurações</h4>
            <p class="card-title-desc">Defina as configurações e preferências específicas para a oficina</p>

            <form>
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label for="manufacturername">Mínimo de Dias para Pagamento com Boleto</label>
                    <input
                      id="minimumDaysBankSlip"
                      name="minimumDaysBankSlip"
                      type="tel"
                      class="form-control"
                      placeholder="5 (padrão)"
                      v-model="user.minimumDaysBankSlip"
                    />
                  </div>
                </div>

                <div class="col-2">
                  <div class="form-group">
                    <label for="manufacturername">Dias p/ vencimento do boleto</label>
                    <input
                      id="daysToBankSlipDueDate"
                      name="daysToBankSlipDueDate"
                      type="tel"
                      class="form-control"
                      placeholder="1 (padrão)"
                      v-model="user.daysToBankSlipDueDate"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox mb-3">
                      <input type="checkbox" class="custom-control-input" id="addTaxesToBankSlip" v-model="user.addTaxesToBankSlip" />
                      <label class="custom-control-label" for="addTaxesToBankSlip">Adicionar impostos no valor do boleto</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="user.addTaxesToBankSlip">
                <div class="col-2">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text">%</span>
                      </div>
                      <input id="bankSlipTaxes" name="bankSlipTaxes" type="tel" class="form-control" v-model="user.bankSlipTaxes" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <button type="submit" class="btn btn-primary mr-1 mt-3" @click="saveConfig">Salvar Configurações</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-4 align-items-center">
      <div class="col-sm-6">
        <router-link
          tag="a"
          to="/hirers"
          class="btn text-muted d-none d-sm-inline-block btn-link"
        >
          <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de oficinas
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  img.user-avatar {
    object-fit: cover;
  }

  .form-control[readonly] {
    background-color: #f1f1f1;
  }

  .avatar-letter-big {
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;
    background-color: rgb(241, 101, 73, 0.3);
    color: #F16549;
    line-height: 120px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
  }
</style>